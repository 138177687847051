.footersection{
    background: #5b5b5b;
}
.footersection p, .footersection h3{
    color: #fff;
    padding: 30px;
}

.footersection h3{
    color: #fff;
    font-weight: 700;
    padding: 30px;
}
.social-div a{
     background: #5b5b5b!important;
}
.social-div Fontawesome{
    background: #5b5b5b!important;
}
.footerr2{
    text-align: left;
}
.footerr2 .nav-link{
    color: #fff!important;
    text-align: left!important;
    margin-left: 30px!important;
}
.rightsSec{
    background: #363636;
    height: 100px;
    width: 100%;
}
.rightsSec p{
    padding-top: 40px;
}
.fontIconnFoot{
    background: #5b5b5b!important;
    color: #fff;
    font-size: 2rem;
}