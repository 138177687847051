$c-primary: #0070f3;
$c-secondary: #757575;
$c-tertiary: #ffffff;
$c-success: #28a745;


//@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Jacquard+24&family=Lato&display=swap');

/* Navbar styles */
.navbar-cert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 80px;
  padding: 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  .logo img{
    margin-left: 50px;
    width: 200px!important;
    height: 60px;
    
  }
  
  .nav-links {
    display: flex;
  }
  
  .nav-link {
    font-size: 16px;
    color: #000;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  
    &:hover {
      color: #000;
    }
  }
  .nav-item{
  margin-right: 50px;
  margin-top: 10px;
  right: 0;
  font-weight: 600;
  color: #000;
  }
  .nav-item a{
    text-decoration: none;
    font-size: 1.2rem;
    right: 0;
  font-weight: 600;
  color: #000;
  }
  
  .nav-item a:hover{
  color: #000;
  }
}



/* Footer styles */
.footerr {
  background-color: #e0e0e0;
  color: #333;
  text-align: center!important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.footerr-text {
  font-size: 14px;
  text-align: center!important;
}
.badge-container{
  margin-top: 30px!important;
  margin-bottom: 30px;
  width: 380px!important;
    height: 650px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    

    .badgeImg{
      margin-top: 70px;
      width: 380px;
    height: 550px!important;
    }
}

.certificate-containere {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;

.certBackk, .badgeBackk{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto!important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
background-color: #f3f3f3;
}

.icon-wrapper img{
height: 120px;
width: 120px;
margin: 20px;
padding: 15px;
border-radius: 20px;
}
.icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 50px;
}

.active-icon {
  cursor: pointer;
  background-color: #e0e0e0;
  /* Add styles for the active state of the icons */
}

.inactive-icon {
  cursor: pointer;
  /* Add styles for the inactive state of the icons */
}
// .certificated {
//   margin-top: 50px!important;
//   margin-bottom: 50px!important;
//   height: 540px!important;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
//   border: 5px solid #013a63;
//   //background-color: $c-tertiary!important;
//   background-image: url('../../img/backcert1.jpg');
//   background-size: cover;
//   //background-image: url('../../img/blue-abstract-gradient-wave-wallpaper.jpg');
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

//   .bodbox{
//     width: 740px;
//   height: 540px!important;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   //padding: 20px;
//   //border: 3px solid #013a63;
//   //background-color: $c-tertiary!important;
//   background: transparent;
//   }
//   .header {
//     display: flex;
//     align-items: center;
//     text-align: center;

//     .favico img{
//       align-items: center;
//       width: 60px!important;
//       height: 60px;
//       // margin-right: 40px;
//       // margin-left: 30px;
//     }

//     .title {
//       text-align: center;
//       font-size: 40px;
//       font-weight: 600;
//       margin-left: 30px;
//       color: #333;
//       // font-family: 'Tangerine', cursive;
//     }
//   }
//   .title1{
//     margin: 0;
//     text-align: center;
//   }
//   .signature{
//     font-size: 1.1rem;
//     text-align: center;
//     font-weight: 600;
//   }
//   .body {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     .name {
//       font-size: 25px!important;
//       font-weight: bold;
//       font-family: 'Poiret One', cursive;
//       color: #333;
//       margin-bottom: 10px;
//       text-decoration: 2px underline;
//     }
//   }
//   .qrcode-container{
//     padding: 15px;
//   }
//   .qrCode{
//     height: 80px!important;
//     width: 80px!important;
//   }
// }

.certificatedde {
  font-family: "Jacquard 24", system-ui;
  border: 2px solid #000;
  padding: 20px;
  width: 1000px;
  height: auto;
  margin: 20px auto;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.bodboxx {
  border: 2px solid #000;
  padding: 20px;
}

// .header {
//   text-align: center;
//   margin-bottom: 20px;
// }

.state {
  font-size: 1.6em;
  text-align: center;
}

.doc-info {
  margin-top: 10px;
  font-size: 0.9em;
}

.official-use {
  font-size: 0.8em;
  font-style: italic;
}

.cert-number {
  font-size: 1.2em;
  font-weight: bold;
}

.title {
  text-align: center;
  font-size: 2.7em;
  margin-bottom: 20px;
  //color: #0133a8;
}

.info {
  margin-bottom: 20px;
}

.state-county {
  font-weight: bold;

}

.minister-info {
  margin-top: 10px;
}

.body {
 // text-align: center;
  margin-bottom: 20px;
  margin-left: 60px;
}
.body1 {
  // text-align: center;
  font-size: 1.5em;
   padding: 10px;
   color: #7f7f7f;
 }
.name {
  font-family: Arial, sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  color: #505050;
}
.name1 {
  font-family: "EB Garamond", serif;
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
}
.name2 {
  font-family: Arial, sans-serif;
  font-size: 1em;
  padding: 10px;
  font-weight: bold;
}
.and {
  font-size: 1.2em;
  margin-top: 10px;
  margin-bottom: 10px;
}




.signature-name {
  font-weight: bold;
}

.qr-and-signature {
  
  display: flex;
  justify-content: space-between;
}

.qrcode-container {
  .qrCode{
        height: 80px!important;
        width: 80px!important;
      }
}

.signatures {
  text-align: center;
}

.signature, .signature1 {
  font-size: 0.9em;
}

  @media only screen and (max-width: 768px) {
    .badge-container{
      margin: 20px auto!important;
        width: 380px;
        height: 550px!important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        
    
        .badgeImg{
          margin-top: 70px;
        }
      }
    .certificated {
      margin: 20px auto!important;
      //width: max-content!important;
      display: flex;
      align-self: center;
      width: auto !important;
      height: auto !important;
      padding: 10px!important;
    }
  
    .bodbox {
      width: 60%!important;
      height: auto !important;
      padding: 10px;
    }
  
    .header .favico img {
      width: 50px !important;
      height: 50px!important;
    }
  
    .header .title {
      font-size: 20px!important;
    }
  
    .title1 {
      font-size: 14px!important;
    }
  
    .body .name {
      font-size: 20px!important;
    }
    .qrcode-container{
      padding: 5px!important;
    }
    .qrCode{
      height: 30px!important;
      width: 30px!important;
    }
    .icon-wrapper img{
      height: 50px;
      width: 50px;
      margin: 20px;
      padding: 10px;
      border-radius: 10px;
      }
      .icon-wrapper {
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
      }
      
  }
  @media only screen and (max-width: 1100px) {
    .certificated {
      width: auto !important;
      height: auto !important;
      padding: 10px!important;
    }
  
    .bodbox {
      width: 70%!important;
      height: auto !important;
      padding: 10px;
    }
  
    .header .favico img {
      width: 50px !important;
      height: 50px!important;
    }
  
    .header .title {
      font-size: 20px!important;
    }
  
    .title1 {
      font-size: 14px!important;
    }
  
    .body .name {
      font-size: 28px!important;
    }
    .qrcode-container{
      padding: 5px!important;
    }
    .qrCode{
      height: 30px!important;
      width: 30px!important;
    }
    .icon-wrapper img{
      height: 50px;
      width: 50px;
      margin: 20px;
      padding: 10px;
      border-radius: 10px;
      }
      .icon-wrapper {
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
      }
      
  }

  }

  .infosec{
    margin: 0 100px;
  }
.leftsec{
  padding-right: 50px;

}
.coursename{
  font-weight: 600;
  font-size: 2rem;
  color: #013a63;
  text-align: left;
  padding-left: 25px!important;
  padding-top: 10px;
}
.check img{
  height: 60px;
  width: 60px;
}
.stuname{
  margin-top: 50px;
  padding-left: 0px!important;
  font-size: 1.7rem;
  text-align: left;
}
.stuID{
  padding-left: 0px!important;
  text-align: left;
  font-size: 1.3rem;
}
.description{
  text-align: left;
  color: #757575;
  padding-top: 20px;
  padding-bottom: 20px;
}
.studNameDiv{
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  text-align: left;
  font-weight: 600;
}
.issuerNamepop{
  display: flex;
  align-items: center;
  //padding-left: 0px!important;
  font-size: 1.7rem;
  text-align: left;
  padding-left: 20px;
  .issuerfontIcon1{
    color: #013a63;
  }
  .issuerfontIcon{
    color: #b6b6b6;
  }
  .issuerr{
    padding-left: 15px;
  }
}
.issuerName{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 0px!important;
  font-size: 1.7rem;
  text-align: left;

  .issuerfontIcon1{
    color: #013a63;
  }
  .issuerfontIcon{
    color: #b6b6b6;
  }
}
.viewCreds{
  color: #0070f3!important;
  font-weight: 600!important;
  margin-left: 60px;
}
.issueon, .expiry{
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
  margin: 20px 0;
}

.rightsec{

}
.footer11 {
  padding: 30px;
  width: 100%;
  height: max-content;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  //border: 2px solid #2d9cdb;
  background-color:#013a63 ;
  //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

  .yellowBadge img{
    height: 130px;
    width: 70px;
  }
  .iconns-div{
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .iconns {
    
    background: transparent;
    padding: 0; /* Adjust padding as needed */
    font-size: 20px!important; /* Adjust font size as needed */
    color: #013a63;
    margin-right: 16px;
    padding: 18px;
    
    background: #fff;
    border-radius: 5px;
  }
  .iconns1 {
    background: transparent;
    padding: 0; /* Adjust padding as needed */
    font-size: 30px; /* Adjust font size as needed */
    color: #013a63;
    margin-right: 8px;
    
  }

.verify{
font-size: 1.2rem;
font-weight: 700;
color: #fff;
text-align: left;
padding: 10px 0;
}
.verify1{
font-size: 1rem;
font-weight: 400;
color: #f3f3f3;
text-align: left;

}



.verify-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  color: #013a63;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;

}
  // .verify-btn {
  //   margin-top: 20px;
  //   color: #2d9cdb;
  //   background-color: #fff;
  //   border: none;
  //   border-radius: 10px;
  //   cursor: pointer;

   
  // }
}
.footer22 {
  width: 100%;
  height: max-content;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

.verify{
font-size: 1.5rem;
font-weight: 600;
color: #333!important;
text-align: left;
padding: 10px 0 20px 0;

}
.verify1{
display: flex;
align-items: center;
margin-bottom: 10px;
font-size: 1rem;
font-weight: 600;
color: #757575;
text-align: left;

.certID{
  padding-left: 15px;
  }
}
.fontIcon22{
  color: #3498db;
  margin-right: 30px!important;
  padding: 0!important;
  margin-left: 30px;
}
}

.certID{
  padding-left: 15px;
  }

  .datess{
    padding-left: 15px;
  }

  .expiryy{
    padding-left: 45px;
  }

  .footer2 {
    margin: 50px 0;
    padding: 30px;
    width: 100%;
    height: max-content;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #c3c3c3;
    //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

.verify{
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  text-align: left;
  padding: 10px 0 20px 0;
  
}
.verify1{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #757575;
  text-align: left;

  span{
    color: #000;
    margin-left: 7px;
  }
  .fontIcon21{
    color: #2d9cdb;
    margin-right: 10px!important;
    padding: 0!important;
  }
  .fontIcon22{
    color: #ffb700;
    margin-right: 10px!important;
    padding: 0!important;
  }
}
    .verify-btn {
      display: flex;
  align-items: center;
  justify-content: center;
      margin-top: 20px;
      width: 100%;
      height: 50px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 700;
      color: #2d9cdb;
      background-color: #fff;
      border: 1px solid #c3c3c3;
      border-radius: 10px;
      cursor: pointer;

     
      // &:hover {
      //   background-color: darken(#fff, 10%);
      // }
    }
    .issuerfontIcon{
      color: #2d9cdb;
      background: none;
      margin-left: 0px;
    }
  }

  .pdfbtn{
    display: flex;
  align-items: center;
  justify-content: center;
    margin-top: 40px;
    margin-left: 10px!important;
    margin-bottom: 40px;
      height: 50px;
      padding: 10px 10px;
      font-size: 16px;
      font-weight: 500!important;
      color: #000;
      background-color: #fff;
      border-radius: 7px;
      border: 1px solid #e7e7e7!important;
      transition: 0.3s;
      cursor: pointer;
      justify-content: center;

      .fontIcon{
        background: transparent;
        padding: 0; /* Adjust padding as needed */
        font-size: 16px; /* Adjust font size as needed */
        color: #6c6c6c;
        margin-right: 8px;
      }
      .fontIcon1{
        background: transparent;
        padding: 0; /* Adjust padding as needed */
        font-size: 18px; /* Adjust font size as needed */
        color: #6c6c6c;
        margin-left: 8px;
      }
  }
  .pdfbtn:hover{
    background-color: #e7e7e7;
  }

  .verify-btnn{
    margin-top: 40px;
      width: 50%;
      height: 50px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 400;
      color: $c-tertiary;
      background-color: #2d9cdb;
      border: none;
      border-radius: 10px;
      cursor: pointer;
  }

  .skillsdiv p{
color: #000;
font-size: 1.2rem;
margin: 10px 0;
font-weight: 600;
  }
  .allSkills{
    margin: 10px;
    color: #000;
    background-color: #e7e7e7;
    padding: 10px;
    border-radius: 5px ;
  }

.certificate-swal{
  width: 100%!important;
  text-align: left!important;
}

  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;

    .spinner {
      margin-left: 70px;
      border: 5px solid #f3f3f3;
      border-top: 5px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;
    }
    
    .spinner-text {
      margin-top: 10px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }
    
    .spinner1 {
      border: 5px solid #f3f3f3;
      border-top: 5px solid #3498db;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }

  .success {
    color: #3498db;
    font-size: 24px;
  }
    .popup {
      width: 600px!important;
      background-color: $c-tertiary;
      border-radius: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .popup-title {
          font-size: 1.8rem;
          font-weight: bold;
          color: #013a63;
          text-align: center;
          margin-top: 20px;
          margin-left: 30px;
        }

        .popup-close {
          position: relative;
          top: 10px;
          right: 10px;
          font-size: 20px;
          color: #7f7f7f;
          cursor: pointer;
          background-color: transparent;
          border: none;
          outline: none;
        }
        
        .popup-body {
          padding: 20px;
        }

        
        
        // .verification-item {
        //   display: flex;
        //   justify-content: space-between;
        //   align-items: center;
        //   margin-bottom: 10px;
        // }
        
        // .verification-label {
        //   font-weight: bold;
        //   font-size: 1.2rem;
        //   margin-right: 10px;
        //   color: #3498db!important;
        // }
        
        // .verification-result {
        //   display: flex;
        //   align-items: center;
        //   color: #fff;
        //   background-color: #5ebd73;
        //   padding: 5px 10px;
        //   border-radius: 5px;
        // }
        
        // .success {
        //   background-color: #5ebd73;
        // }
        
        .popup-footer {
          padding: 20px;
          background-color: #fff;
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid #ddd;
        }
        
        .share-btn {
          background-color: #2d9cdb;
          color: #fff;
          font-weight: bold;
          border-radius: 5px;
          padding: 10px 20px;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }
        
        .share-btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
        
        .share-btn:active {
          transform: translateY(0);
          box-shadow: none;
        }
      }
    }
  }
  .verification-itemm {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin: 0 40px;
  }

  .verification-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .verification-item .verification-label {
    font-weight: bold;
    margin-right: 10px;
  }
  .verification-item .verification-result {
    display: flex;
    align-items: center;
  }
  .verification-item .spinner1,
  .verification-item .success {
    margin: 0 30px;
    font-size: 20px;
    width: 30px;
      height: 30px;
  }
  .verified{
    font-weight: bold;
    font-size: 1.4rem;
    margin-left: 15px;
  }
  .verified1{
    margin-left: 20px;
  }
  .success1 img{
    height: 45px;
    width: 45px;
    margin-left: 20px;
  }

  /* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .navbar-cert {
    padding: 0 10px;
  }
  .logo img {
    margin-left: 10px;
    width: 120px !important;
    height: 40px;
  }
  .nav-links {
    display: none;
  }
  .nav-item {
    margin-right: 10px;
    margin-top: 5px;
  }
  .nav-item a {
    font-size: 1rem;
  }
  // .badge-container,
  // .certificate-container {
  //   width: 100%;
  //   height: auto !important;
  //   padding: 10px;
  // }
  .footer11 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  .popup {
    width: 90%;
    max-width: 450px;
  }
  .popup-title {
    font-size: 1.6rem;
  }
  .popup-body,
  .popup-footer {
    padding: 10px;
  }
  // .pdfbtn {
  //   width: 20%;
  // }
  .rightsec,
  .leftsec {
    padding: 20px;
  }
  .iconns {
    
    background: transparent;
    padding: 0; /* Adjust padding as needed */
    font-size: 10px!important; /* Adjust font size as needed */
    color: #013a63;
    margin-right: 8px;
    padding: 8px;
  }
  .shareIcon{
    cursor: pointer!important;
  }
  .rightsec {
    text-align: center;
  }
  .infosec {
    margin: 0;
  }
  .coursename {
    padding-left: 15px !important;
  }
  .description {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .viewCreds {
    margin-left: 10px;
  }
}



/* Additional Responsive Styles for Even Smaller Devices */
@media only screen and (max-width: 480px) {
  .certificated {
    width: 90%;
    height: auto !important;
    padding: 5px;
  }

  .bodbox {
    width: 90%;
    height: auto !important;
    padding: 5px;
  }

  .header .favico img {
    width: 40px !important;
    height: 40px;
  }

  .header .title {
    font-size: 24px;
  }

  .title1 {
    font-size: 16px;
  }

  .body .name {
    font-size: 20px;
  }
}

/* Additional Responsive Styles for Smaller Devices */
@media only screen and (max-width: 1200px) {
  .navbar-cert {
    height: 50px;
    padding: 0 5px;

    .logo img {
      margin: 10px 5px 5px 5px!important;
      width: 100px !important;
      height: 30px;
    }
  }

  .nav-links {
    display: none;
  }
  .nav-item {
    margin-right: 5px!important;
    margin-top: 5px!important;
  }
  .nav-item a {
    font-size: 0.85rem!important;
  }
  .badge-container,
  .certificate-container {
    width: 100%;
    max-width: 100%;
    height: auto !important;
    padding: 5px;
  }
  // .footer11 {
  //   flex-direction: column;
  //   align-items: center;
  //   text-align: center;
  // }
  .popup {
    width: 90%;
    max-width: 400px;
    height: 70vh!important;
    scroll-behavior: auto;
  }
  .popup-title {
    font-size: 1.2rem;
  }
  .popup-body,
  .popup-footer {
    padding: 5px;
  }
  // .pdfbtn {
  //   width: 100%;
  // }
  .rightsec,
  .leftsec {
    //margin: 30px!important;
  }
  .rightsec {
    text-align: center;
  }
  .infosec {
    margin: 0;
  }
  .coursename {
    padding-left: 25px !important;
    font-size: 1.5rem!important;
  }
  .check img{
    height: 50px!important;
    width: 50px!important;
  }
  .description {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .viewCreds {
    margin-left: 5px;
  }
  .icon-wrapper img {
    height: 100px;
    width: 100px;
    margin: 10px;
    padding: 10px;
  }
  .icon-wrapper {
    margin-right: 10px;
  }
  .pdfbtn {
    margin-top: 20px;
  }
  .leftsec {
    padding-right: 10px;
  }
  .rightsec {
    padding-left: 10px;
  }
  // .footer2 {
  //   margin: 20px 0 !important;
  // }
  .verify {
    font-size: 1rem;
  }
  .verify1 {
    font-size: 0.8rem;
  }
  .verify-btnn {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-size: 14px;
  }
  .yellowBadge img{
    height: 80px!important;
    width: 50px!important;
  }
}

@media only screen and (max-width: 1000px) {
  .navbar-cert {
    height: 60px;
    padding: 0 5px;
  }
  .logo img {
    margin-left: 5px;
    width: 100px !important;
    height: 30px;
  }
  .nav-links {
    display: none;
  }
  .nav-item {
    margin-right: 5px;
    margin-top: 5px;
  }
  .nav-item a {
    font-size: 1rem;
  }
  .badge-container,
  .certificate-container {
    width: 100%;
    max-width: 100%;
    height: auto !important;
    padding: 5px;
  }
  // .footer11 {
  //   flex-direction: column;
  //   align-items: center;
  //   text-align: center;
  // }
  .popup {
    width: 90%;
    max-width: 400px;
  }
  .popup-title {
    font-size: 1.2rem;
  }
  .popup-body,
  .popup-footer {
    padding: 5px;
  }
  // .pdfbtn {
  //   width: 100%;
  // }
  .rightsec,
  .leftsec {
    margin: 30px!important;
  }
  .rightsec {
    text-align: center;
  }
  .infosec {
    margin: 0;
  }
  .coursename {
    padding-left: 25px !important;
    font-size: 1.5rem!important;
  }
  .check img{
    height: 50px!important;
    width: 50px!important;
  }
  .description {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .viewCreds {
    margin-left: 5px;
  }
  .icon-wrapper img {
    height: 100px;
    width: 100px;
    margin: 10px;
    padding: 10px;
  }
  .icon-wrapper {
    margin-right: 10px;
  }
  .pdfbtn {
    margin-top: 20px;
  }
  .leftsec {
    padding-right: 10px;
  }
  .rightsec {
    padding-left: 10px;
  }
  // .footer2 {
  //   margin: 20px 0 !important;
  // }
  .verify {
    font-size: 1rem;
  }
  .verify1 {
    font-size: 0.8rem;
  }
  .verify-btnn {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-size: 14px;
  }
  .yellowBadge img{
    height: 80px!important;
    width: 50px!important;
  }
}

